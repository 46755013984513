import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\nmutation FBLoginMutation(\n  $accessToken : String!\n) {\n  oauthTokenCreate(accessToken:$accessToken, backend: \"facebook\"){\n    errors{\n      message\n    }\n    user{\n      id\n    }\n    token\n  }\n}\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  mutation RegisterAccount(\n    $email: String!\n    $password: String!\n    $redirectUrl: String!\n  ) {\n    accountRegister(\n      input: { email: $email, password: $password, redirectUrl: $redirectUrl }\n    ) {\n      errors {\n        field\n        message\n      }\n      requiresConfirmation\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import gql from "graphql-tag";
import { TypedMutation } from "../../../core/mutations";
var accountRegisterMutation = gql(_templateObject());
export var TypedAccountRegisterMutation = TypedMutation(accountRegisterMutation);
export var FBLoginMutation = gql(_templateObject2());
export var TypedFBLoginMutation = TypedMutation(FBLoginMutation);