import "./scss/index.scss";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { generatePath, Link } from "react-router-dom";
import ReactSVG from "react-svg";
import { TaxedMoney } from "@components/containers";
import { commonMessages } from "@temp/intl";
import { useAuth, useCart, useCheckout } from "@saleor/sdk";
import { Button, Offline, OfflinePlaceholder, Online, Overlay } from "../..";
import { cartUrl, checkoutLoginUrl, checkoutUrl } from "../../../app/routes";
import Loader from "../../Loader";
import Empty from "./Empty";
import ProductList from "./ProductList";
import cartImg from "../../../images/cart.svg";
import closeImg from "../../../images/x.svg";

var Cart = function Cart(_ref) {
  var _checkout$shippingMet;

  var overlay = _ref.overlay;

  var _useAuth = useAuth(),
      user = _useAuth.user;

  var _useCheckout = useCheckout(),
      checkout = _useCheckout.checkout;

  var _useCart = useCart(),
      items = _useCart.items,
      removeItem = _useCart.removeItem,
      subtotalPrice = _useCart.subtotalPrice,
      shippingPrice = _useCart.shippingPrice,
      discount = _useCart.discount,
      totalPrice = _useCart.totalPrice;

  var shippingTaxedPrice = (checkout === null || checkout === void 0 ? void 0 : (_checkout$shippingMet = checkout.shippingMethod) === null || _checkout$shippingMet === void 0 ? void 0 : _checkout$shippingMet.id) && shippingPrice ? {
    gross: shippingPrice,
    net: shippingPrice
  } : null;
  var promoTaxedPrice = discount && {
    gross: discount,
    net: discount
  };

  var missingVariants = function missingVariants() {
    return items.find(function (item) {
      return !item.variant || !item.totalPrice;
    });
  };

  return React.createElement(Overlay, {
    testingContext: "cartOverlay",
    context: overlay
  }, React.createElement(Online, null, React.createElement("div", {
    className: "cart"
  }, React.createElement("div", {
    className: "overlay__header"
  }, React.createElement(ReactSVG, {
    path: cartImg,
    className: "overlay__header__cart-icon"
  }), React.createElement("div", {
    className: "overlay__header-text"
  }, React.createElement(FormattedMessage, {
    id: "components.OverlayManager.Cart.Cart.1316031908",
    defaultMessage: "My bag,"
  }), " ", React.createElement("span", {
    className: "overlay__header-text-items"
  }, (items === null || items === void 0 ? void 0 : items.reduce(function (prevVal, currVal) {
    return prevVal + currVal.quantity;
  }, 0)) || 0, " ", React.createElement(FormattedMessage, {
    id: "components.OverlayManager.Cart.Cart.2287516380",
    defaultMessage: "items"
  }))), React.createElement(ReactSVG, {
    path: closeImg,
    onClick: overlay.hide,
    className: "overlay__header__close-icon"
  })), (items === null || items === void 0 ? void 0 : items.length) ? React.createElement(React.Fragment, null, missingVariants() ? React.createElement(Loader, {
    full: true
  }) : React.createElement(React.Fragment, null, React.createElement(ProductList, {
    lines: items,
    remove: removeItem
  }), React.createElement("div", {
    className: "cart__footer"
  }, React.createElement("div", {
    className: "cart__footer__price"
  }, React.createElement("span", null, React.createElement(FormattedMessage, commonMessages.subtotal)), React.createElement("span", null, React.createElement(TaxedMoney, {
    "data-test": "subtotalPrice",
    taxedMoney: subtotalPrice
  }))), shippingTaxedPrice && shippingTaxedPrice.gross.amount !== 0 && React.createElement("div", {
    className: "cart__footer__price"
  }, React.createElement("span", null, React.createElement(FormattedMessage, commonMessages.shipping)), React.createElement("span", null, React.createElement(TaxedMoney, {
    "data-test": "shippingPrice",
    taxedMoney: shippingTaxedPrice
  }))), promoTaxedPrice && promoTaxedPrice.gross.amount !== 0 && React.createElement("div", {
    className: "cart__footer__price"
  }, React.createElement("span", null, React.createElement(FormattedMessage, commonMessages.promoCode)), React.createElement("span", null, React.createElement(TaxedMoney, {
    "data-test": "promoCodePrice",
    taxedMoney: promoTaxedPrice
  }))), React.createElement("div", {
    className: "cart__footer__price"
  }, React.createElement("span", null, React.createElement(FormattedMessage, commonMessages.total)), React.createElement("span", null, React.createElement(TaxedMoney, {
    "data-test": "totalPrice",
    taxedMoney: totalPrice
  }))), React.createElement("div", {
    className: "cart__footer__button"
  }, React.createElement(Link, {
    to: generatePath(cartUrl, {
      token: null
    })
  }, React.createElement(Button, {
    testingContext: "gotoBagViewButton",
    secondary: true
  }, React.createElement(FormattedMessage, {
    id: "components.OverlayManager.Cart.Cart.2426849470",
    defaultMessage: "Go to my bag"
  })))), React.createElement("div", {
    className: "cart__footer__button"
  }, React.createElement(Link, {
    to: user ? checkoutUrl : checkoutLoginUrl
  }, React.createElement(Button, {
    testingContext: "gotoCheckoutButton"
  }, React.createElement(FormattedMessage, commonMessages.checkout))))))) : React.createElement(Empty, {
    overlayHide: overlay.hide
  }))), React.createElement(Offline, null, React.createElement("div", {
    className: "cart"
  }, React.createElement(OfflinePlaceholder, null))));
};

export default Cart;