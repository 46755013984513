import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import classNames from "classnames";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import Media from "react-media";
import { Link, useHistory } from "react-router-dom";
import { baseUrl } from "../../app/routes";
import { getDBIdFromGraphqlId, slugify } from "../../core/utils";
import { smallScreen } from "../../globalStyles/scss/variables.scss";
import "./scss/index.scss";
import { useFunc, GET_ME } from "@temp/@next/hooks/useQuery";
import { useAuth } from "@saleor/sdk";
export var extractBreadcrumbs = function extractBreadcrumbs(category) {
  var constructLink = function constructLink(item) {
    return {
      link: ["/category", "/".concat(slugify(item.name)), "/".concat(getDBIdFromGraphqlId(item.id, "Category"), "/")].join(""),
      value: item.name
    };
  };

  var breadcrumbs = [constructLink(category)];

  if (category.ancestors.edges.length) {
    var ancestorsList = category.ancestors.edges.map(function (edge) {
      return constructLink(edge.node);
    });
    breadcrumbs = ancestorsList.concat(breadcrumbs);
  }

  return breadcrumbs;
};

var getBackLink = function getBackLink(breadcrumbs) {
  return breadcrumbs.length > 1 ? breadcrumbs[breadcrumbs.length - 2].link : "/";
};

var Breadcrumbs = function Breadcrumbs(_ref) {
  var breadcrumbs = _ref.breadcrumbs;
  var history = useHistory();
  return React.createElement("div", {
    className: "breadcrumb_bar"
  }, React.createElement(Media, {
    query: {
      minWidth: smallScreen
    }
  }, function (matches) {
    return matches ? React.createElement("ul", {
      className: "breadcrumbs"
    }, React.createElement("li", null, React.createElement(Link, {
      to: baseUrl
    }, React.createElement(FormattedMessage, {
      id: "components.Breadcrumbs.index.874908955",
      defaultMessage: "Home"
    }))), breadcrumbs.map(function (breadcrumb, index) {
      return React.createElement("li", {
        key: breadcrumb.value,
        className: classNames({
          breadcrumbs__active: index === breadcrumbs.length - 1
        })
      }, React.createElement(Link, {
        to: breadcrumb.link
      }, breadcrumb.value));
    })) : React.createElement("div", {
      className: "breadcrumbs"
    }, React.createElement("div", {
      onClick: function onClick() {
        return history.goBack();
      }
    }, React.createElement(FormattedMessage, {
      id: "components.Breadcrumbs.index.1347475195",
      defaultMessage: "Back"
    })));
  }), React.createElement(PointComp, {
    showExpire: false
  }));
};

export var PointComp = function PointComp(_ref2) {
  var showExpire = _ref2.showExpire,
      showName = _ref2.showName,
      need_refetch = _ref2.need_refetch;

  var _useFunc = useFunc(GET_ME),
      _useFunc2 = _slicedToArray(_useFunc, 1),
      _useFunc2$ = _useFunc2[0],
      data = _useFunc2$.data,
      loading = _useFunc2$.loading,
      fetch = _useFunc2$.fetch,
      refetch = _useFunc2$.refetch;

  var auth = useAuth();
  React.useEffect(function () {
    if (auth.user) {
      if (need_refetch) refetch();else fetch();
    }
  }, [auth.user]); // console.log(auth) 
  // console.log(data)

  if (!auth.user) return React.createElement("div", null);
  var points = "---";
  var expire_point = "---";
  var expire_date = "--------";

  if (!loading && data) {
    // console.log(data.data.me.metadata)
    try {
      points = data.data.me.metadata.filter(function (item) {
        return item.key === "points";
      })[0].value;

      if (showExpire) {
        expire_point = data.data.me.metadata.filter(function (item) {
          return item.key === "expire_points";
        })[0].value;
        expire_date = data.data.me.metadata.filter(function (item) {
          return item.key === "points_expire_date";
        })[0].value;
      }
    } catch (error) {
      points = "0";
    } // console.log(data.data.me.metadata.filter((item) => item.key ==="points"))

  }

  return React.createElement("div", {
    className: "points_display"
  }, React.createElement("div", null, showName && React.createElement("span", null, "Welcome ", auth.user.firstName, " , "), React.createElement("span", {
    className: "pd_"
  }, "You have "), points, " TPoints"), showExpire && React.createElement("div", null, expire_point, " TPoints", React.createElement("span", {
    className: ""
  }, " will be expired by"), expire_date));
};
export default Breadcrumbs;