import * as React from "react";
import { Overlay, OverlayContext, OverlayType } from "..";
import Cart from "./Cart";
import Login from "./Login";
import MobileNav from "./MobileNav";
import Modal from "./Modal";
import Notification from "./Notification";
import Password from "./Password";
import Search from "./Search";

var OverlayManager = function OverlayManager() {
  return React.createElement(OverlayContext.Consumer, null, function (overlay) {
    switch (overlay.type) {
      case OverlayType.modal:
        return React.createElement(Modal, {
          testingContext: "modal",
          overlay: overlay
        });

      case OverlayType.message:
        return React.createElement(Notification, {
          overlay: overlay
        });

      case OverlayType.cart:
        return React.createElement(Cart, {
          overlay: overlay
        });

      case OverlayType.search:
        return React.createElement(Search, {
          overlay: overlay
        });

      case OverlayType.login:
        return React.createElement(Login, {
          overlay: overlay,
          active: "login"
        });

      case OverlayType.register:
        return React.createElement(Login, {
          overlay: overlay,
          active: "register"
        });

      case OverlayType.password:
        return React.createElement(Password, {
          overlay: overlay
        });

      case OverlayType.sideNav:
        return React.createElement(MobileNav, {
          overlay: overlay
        });

      case OverlayType.mainMenuNav:
        return React.createElement(Overlay, {
          testingContext: "mainMenuOverlay",
          context: overlay
        });

      default:
        return null;
    }
  });
};

export default OverlayManager;