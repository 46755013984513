import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React from "react"; // import { DemoBanner } from "@components/atoms";
// import { demoMode } from "@temp/constants";

import { Footer, MainMenu, MetaConsumer, OverlayManager } from "../components";
import "../globalStyles/scss/index.scss";
import { Routes } from "./routes";
import { GET_ME, useFunc } from "@temp/@next/hooks/useQuery";

var App = function App() {
  var _useFunc = useFunc(GET_ME),
      _useFunc2 = _slicedToArray(_useFunc, 1),
      refetch = _useFunc2[0].refetch;

  React.useEffect(function () {
    refetch();
  }, []);
  return React.createElement(React.Fragment, null, React.createElement(MetaConsumer, null), React.createElement("header", null, React.createElement(MainMenu, null)), React.createElement(Routes, null), React.createElement(Footer, null), React.createElement(OverlayManager, null));
};

export default App;