/* eslint-disable global-require */
import { generatePageUrl } from "./utils";
export var BASE_URL = "/";
export var PRODUCTS_PER_PAGE = 6;
export var SUPPORT_EMAIL = "rewards.trenndii@gmail.com";
export var PROVIDERS = {
  BRAINTREE: {
    label: "Braintree"
  },
  DUMMY: {
    label: "Dummy"
  },
  STRIPE: {
    href: "https://js.stripe.com/v3/",
    label: "Stripe"
  },
  POINT: {
    label: "Point Payment"
  }
};
export var STATIC_PAGES = [{
  label: "About",
  url: generatePageUrl("about")
}];
export var SOCIAL_MEDIA = [{
  ariaLabel: "facebook",
  href: "https://www.facebook.com/trenndii",
  path: require("../images/facebook-icon.svg")
}];
export var META_DEFAULTS = {
  custom: [],
  description: "Trenndii Rewards",
  image: "".concat(window.location.origin).concat(require("../images/logo.svg")),
  title: "Trenndii Rewards",
  type: "website",
  url: window.location.origin
};
export var CheckoutStep;

(function (CheckoutStep) {
  CheckoutStep[CheckoutStep["Address"] = 1] = "Address";
  CheckoutStep[CheckoutStep["Shipping"] = 2] = "Shipping";
  CheckoutStep[CheckoutStep["Payment"] = 3] = "Payment";
  CheckoutStep[CheckoutStep["Review"] = 4] = "Review";
})(CheckoutStep || (CheckoutStep = {}));

export var CHECKOUT_STEPS = [{
  index: 0,
  link: "/checkout/address",
  name: "Address",
  nextActionName: "Continue to Shipping",
  nextStepLink: "/checkout/shipping",
  onlyIfShippingRequired: true,
  step: CheckoutStep.Address
}, {
  index: 1,
  link: "/checkout/shipping",
  name: "Shipping",
  nextActionName: "Continue to Payment",
  nextStepLink: "/checkout/payment",
  onlyIfShippingRequired: true,
  step: CheckoutStep.Shipping
}, {
  index: 2,
  link: "/checkout/payment",
  name: "Payment",
  nextActionName: "Place order",
  // nextActionName: "Continue to Review",
  nextStepLink: "/order-finalize",
  // nextStepLink: "/checkout/review",
  onlyIfShippingRequired: false,
  step: CheckoutStep.Payment
}, {
  index: 3,
  link: "/checkout/review",
  name: "Review",
  nextActionName: "Place order",
  nextStepLink: "/order-finalized",
  onlyIfShippingRequired: false,
  step: CheckoutStep.Review
}];