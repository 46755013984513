import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  ", "\n  ", "\n  query LatestProducts (    \n    $after: String\n    ){\n    products (first : 12 sortBy:{direction: DESC , field:DATE} after:$after ) {\n      edges {\n        node {\n          ...BasicProductFields\n          ...ProductPricingField\n          category {\n            id\n            name\n          }\n          \n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n        hasPreviousPage\n        startCursor\n      }\n    }\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  ", "\n  ", "\n  query FeaturedProducts {\n    shop {\n      homepageCollection {\n        id\n        products(first: 20) {\n          edges {\n            node {\n              ...BasicProductFields\n              ...ProductPricingField\n              category {\n                id\n                name\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import gql from "graphql-tag";
import { TypedQuery } from "../../core/queries";
import { basicProductFragment, productPricingFragment } from "../../views/Product/queries";
export var featuredProducts = gql(_templateObject(), basicProductFragment, productPricingFragment);
export var latestProducts = gql(_templateObject2(), basicProductFragment, productPricingFragment);
export var TypedLatestProductsQuery = TypedQuery(latestProducts);
export var TypedFeaturedProductsQuery = TypedQuery(featuredProducts);